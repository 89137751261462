import React, { useEffect, useState } from 'react';
import useAxios from "../../hooks/useAxios";

const Modal = ({ closeModal, selectedRows, formId, formName, gridColumns, setLoading}: any) => {
    const [format, setFormat] = useState('xlsx');
    const [rows, setRows] = useState([]);
    const axios = useAxios();

    const handleFormatChange = (e: any) => {
        setFormat(e.target.value);
    };

    const handleExport = async () => {
        setLoading(true);
        const _ids = rows.map((r: any) => r._id);
        let newExportObject: any = { format: format };
        newExportObject.fid = formId;
        newExportObject.limit = 100000;
        newExportObject._ids = _ids;
        newExportObject.offset = 1;
        newExportObject.searchStr = {};
        newExportObject.gridColumns = gridColumns;
        console.log(newExportObject);

        let result: any = await axios.post(`forms/export-records`, newExportObject, { responseType: 'blob' });
        let url = window.URL.createObjectURL(result);
        setLoading(false);
        let link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('hidden', 'true');
        link.setAttribute('download', `${formName}.${format}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        handleClose();
    };

    useEffect(() => {
        setRows(selectedRows);
    }, [selectedRows]);

    const handleClose = () => {
        closeModal(false)
    }

    return (
        <div className="modal-overlay ">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">

                        <div className="close-button" onClick={handleClose}>
                            <i className="fa fa-times closeicon" aria-hidden="true"></i>
                        </div>
                        <h5 className="h5records">Export records</h5>
                        <hr className="hr-width" />
                        <div className="modal-body">
                            <div className="exportcs">
                                <div className="form-group">
                                    <label className="form-label">Choose a format:</label>
                                    <div className="radio-group custom-control-inline">
                                        <span>
                                            <label className="radio-label">
                                                <input
                                                    type="radio"
                                                    name="format"
                                                    value="xlsx"
                                                    checked={format === 'xlsx'}
                                                    onChange={handleFormatChange}
                                                />
                                                &nbsp; Excel (.xlsx)
                                            </label>
                                        </span>
                                        <span className="pl-3">
                                            <label className="radio-label">
                                                <input
                                                    type="radio"
                                                    name="format"
                                                    value="csv"
                                                    checked={format === 'csv'}
                                                    onChange={handleFormatChange}
                                                />
                                                &nbsp; CSV (.csv)
                                            </label>
                                        </span>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-close" onClick={handleClose}>
                                        Close
                                    </button>
                                    <button type="button" className="btn btn-primary ml-2" onClick={handleExport}>
                                        Export
                                    </button>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};


export default Modal;
