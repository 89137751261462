import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import moment from "moment-timezone";
import DataGrid from "../grid/DataGrid";
import loaderImage from "../../../assets/loader-sm.gif";
import bigLoader from "../../../assets/loader-lg.gif";

const HistoryLogs = () => {
    const [gridColumns, setGridColumns] = useState([]);
    const [recordData, setRecordData] = useState([]);
    const [totalRecord, setTotalRecord] = useState(null);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(15);

    //const [setSelectedRows]: any = useState([]);

    const [gridSettingsFetched] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [loading, setLoading] = useState(false);

    const [isGridRefresh, setIsGridRefresh] = useState(false);

    const axios = useAxios();
    const loc: any = document.location;
    const params = new URL(loc).searchParams;
    const formId = params.get("formId");

    const fetchFormRecords = async (page: any) => {
        setShowLoader(true);
        // let Fdata: any = await axios.post("common/history-logs/", { ...page, formId: formId });

        let Fdata: any = await axios.get("/common/history-logs/", { params: { ...page, key: formId } });
        //const gridData = { total: data[0].total[0] ? data[0].total[0].total : 0, data: data[0].data };

        let data = Fdata.data[0].data;
        data = data.map((d: any) => {
            return { ...d, UpdatedDate: d.UpdatedDate ? moment(d.UpdatedDate).format("DD/MM/YYYY hh:mm A") : "", UpdatedBy: d.UpdatedBy ? (d.UpdatedBy.LastName ? d.UpdatedBy.FirstName + " " + d.UpdatedBy.LastName : d.UpdatedBy.FirstName) : "" };
        });
        let form = Fdata.data.form;
        let total = Fdata.data[0].total[0] ? Fdata.data[0].total[0].total : 0;
        setRecordData(data);
        setTotalRecord(total);
        setShowLoader(false);
    };

    const columns = [
        {
            Header: "Sr. no.",
            accessor: "tp_serialNumber",
            Cell: ({ row }: any) => row.index + 1,
            width: 80,
        },
        {
            Header: "Event",
            accessor: "EventName",
            render: (data: any) => data,
            width: 80,
        },
        {
            Header: "Updated On",
            accessor: "UpdatedDate",
            render: (data: any) => data,
            width: 80,
        },
        {
            Header: "Updated By",
            accessor: "UpdatedBy",
            render: (data: any) => data,
            width: 80,
        },
    ];
    useEffect(() => {
        fetchFormRecords({ offset: pageIndex + 1, limit: pageSize, searchStr: {}, field: "Name" });
    }, [gridSettingsFetched, pageIndex, pageSize]);

    const handlePageChange = (newPageIndex: any) => {
        setPageIndex(newPageIndex);
    };

    const handlePageSizeChange = (newPageSize: any) => {
        setPageSize(newPageSize);
        setPageIndex(0);
    };

    const refreshGrid = () => {
        fetchFormRecords({ offset: pageIndex + 1, limit: pageSize, searchStr: {}, field: "Name" });
        setIsGridRefresh(!isGridRefresh);
    };

    const setSelectedRows = () => {};

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <img src={bigLoader} alt="Loading..." />
                </div>
            )}
            <div className="whole-container">
                <div className="record-container">
                    <div className="d-flex pl-0 pr-0 pt-0">
                        <div className="flex-grow-1 mb-2">
                            <h2 className="h4 mb-0">History Logs</h2>
                            <span className="small pt-2">{totalRecord} items</span>
                        </div>
                        <div className="text-right flex-grow-1 gird-button-list hide-edit mb-2 mt-1">
                            <div className="d-flex justify-content-end">
                                <div className="tooltip-container">
                                    <button className="btnRefresh btn btn-sm btn-outline-secondary" onClick={() => refreshGrid()}>
                                        <i className="fas fa-sync-alt"></i>
                                    </button>
                                    <span className="tooltip-text">Refresh</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {showLoader && (
                        <div className="loader-overlay">
                            <img src={loaderImage} alt="Loading..." />
                        </div>
                    )}
                    {<DataGrid columns={columns} data={recordData} pageIndex={pageIndex} pageSize={pageSize} totalRecord={totalRecord} onPageChange={handlePageChange} onPageSizeChange={handlePageSizeChange} onSelectionChange={setSelectedRows} setHighlight={() => {}} onSearchListChange={() => {}} gridOptions={{ rowSelection: false, actionColumn: false, searchColumn: false }} />}
                </div>
            </div>
            <style>
                {`
                .fontSize {
                    font-size: 11px;
                }
                    .right-panel {
                        height: calc(100vh - 107px);
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: #fff;
                        z-index: 100;
                        width: 500px;
                        padding: 1.5rem;
                        overflow: auto;
                        transition: all .3s;
                        margin-right: 0;
                        box-shadow: 0 0 20px rgba(0,0,0,.1);
                    }

                    .right-panel.show {
                        transform: translateX(0);
                    }
                    .col-6 {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                    .text-lg-right {
                        text-align: right !important;
                    }
                    .ml-2, .mx-2 {
                        margin-left: 0.5rem !important;
                    }
                    .pb-3, .py-3 {
                        padding-bottom: 1rem !important;
                    }
                    
                    .border-bottom {
                        border-bottom: 1px solid #e3e6f0 !important;
                    }
                    .row {
                        display: flex;
                        flex-wrap: wrap;
                        margin-right: -0.5rem;
                        margin-left: -0.5rem;
                    }
                    
                    .btn-sm{
                        padding: 0.25rem 0.5rem;
                        font-size: 0.7109375rem;
                        line-height: 1.5;
                        border-radius: 0.2rem;
                    }
                    .btn-primary {
                        color: #fff;
                        background-color: #075da8;
                        border-color: #075da8;
                    }
                `}
            </style>
        </>
    );
};

export default HistoryLogs;
