import { useState, useRef, useEffect } from 'react';
import useEmailTab from './EmailTab';
import SubjectInput from './SubjectEditor';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setCheck, updateEmailSettings } from '../../../store';
import useAxios from '../../../hooks/useAxios';
import { Success } from '../../../config/swal';
import TemplateEditor from './TemplateEditor';
import PreviewEditor from './PreviewEditor';
import { Tooltip } from 'react-tooltip';


interface Notification {
    Enabled: boolean;
    RecipientEmail: string;
    SenderEmail: string;
    SenderName: string;
    Bcc: string;
    Content: string;
    Subject: string;
    ShowBlankFields: boolean;
}

const AdminEmail = () => {
    const formData = useSelector((state: RootState) => state.project.formData);
    const verifiedEmails = useSelector((state: RootState) => state.project.verifiedEmails);
    const adminEmail = useSelector((state: RootState) => state.project.emailSettings.Notification);
    const dispatch = useDispatch();
    const axios = useAxios();

    const [showEditor, setShowEditor] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const submitBtn: any = useRef(null);
    const { register, handleSubmit, formState: { errors }, getFieldState, setValue, getValues } = useForm<Notification>({
        values: adminEmail
    });

    const { activeTab, EmailTab } = useEmailTab();

    const framRef: any = useRef(null)
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const rules = {
        Enabled: register('Enabled', {
            onChange: () => {
                onFieldBlur('Enabled');
                dispatch(setCheck(false));
            }
        }),
        RecipientEmail: register('RecipientEmail', {
            required: 'Recipient email is required',
            pattern: { value: emailPattern, message: 'Invalid email address' },
            onBlur: () => onFieldBlur('RecipientEmail'),
            onChange: () => {
                dispatch(setCheck(false));
            }
        }),
        SenderEmail: register('SenderEmail', {
            required: 'Sender email is required', onChange(e) {
                onSenderChange(e);
                onFieldBlur('SenderEmail');
                dispatch(setCheck(false));
            }
        }),
        Bcc: register('Bcc', {
            pattern: { value: emailPattern, message: 'Invalid email address' },
            onBlur: () => onFieldBlur('Bcc'),
            onChange: () => dispatch(setCheck(false))
        }),
        SenderName: register('SenderName', {
            required: 'Sender name is required',
            onBlur: () => onFieldBlur('SenderName'),
            onChange: () => dispatch(setCheck(false))
        }),
        Subject: register('Subject', { required: 'Subject is required' }),
        Content: register('Content', {
            onChange: () => dispatch(setCheck(false))
        }),
        ShowBlankFields: register('ShowBlankFields', {
            onChange: () => {
                onFieldBlur('ShowBlankFields');
                dispatch(setCheck(false));
            },
        }),
    }

    const onFieldBlur = (field: any) => {
        if (getValues(field) !== adminEmail[field]) {
            submitBtn.current.click();
        }
    }
    function onPreviewLoad() {
        setTimeout(() => {
            const iframe: any = document.getElementById('myFrame');
            const body = iframe.contentDocument.body;
            const html = iframe.contentDocument.documentElement;
            const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            iframe.style.height = height + 'px';
        }, 0);
    }

    const onSenderChange = (e: any) => {
        const { SenderName } = verifiedEmails.find((ve: any) => ve._id == e.target.value);
        setValue('SenderName', SenderName)
    }

    const items = formData.Metadata.Content.pages?.map((p: any) => p.elements || []).flat(1) || [];
    // const items2 = formData.Metadata.Content.pages.map((p: any) => p.elements.map((el: any) => { return { "value": el.name, "key": el.valueName } })).flat(1);
    const pages = formData.Metadata.Content.pages || [];

    const onSubmit = async (data: any) => {
        try {
            const value = { ...adminEmail, ...data };
            value['Enabled'] = getValues('Enabled')
            await updateEmailSettings({ _id: formData._id, patchData: { target: 'Notification', value }, axios })(dispatch);
            dispatch(setCheck(true));
            // Success({ title: 'Admin email updated' })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const messageHandler = (event: any) => {
            if (event.data.type == 'EmailContent') {
                if (adminEmail.Content != event.data.content) {
                    setValue('Content', event.data.content)
                    SaveContent(event.data.content)
                }
            }
            if (event.data.type == 'CloseEmailContent') {
                setShowEditor(false)
            }
        }

        window.addEventListener('message', messageHandler);

        return () => {
            window.removeEventListener("message", messageHandler);
        }
    }, [adminEmail, window])

    const SaveContent = async (EmailContentString: string) => {
        const value = { ...adminEmail };
        value['Content'] = EmailContentString
        await updateEmailSettings({ _id: formData._id, patchData: { target: 'Notification', value }, axios })(dispatch);
        // Success({ title: 'Admin email Content updated' })
    }

    const onSubjectChange = (value: string) => {
        setValue('Subject', value);
        dispatch(setCheck(false));
    }
    return (
        <div >
            <div className="form-cb-group mb-2">
                <label style={{paddingRight:'10px'}}>Enable Admin Email </label>
                <label className="switch">
                    <input {...rules.Enabled} type="checkbox" />
                    <span className="slider round"></span>
                </label>
                <a style={{color: '#075da8', marginLeft:'8px'}} id="adminEmailInfo"><i className="fa fa-info-circle"></i></a>
                <Tooltip
                    anchorSelect="#adminEmailInfo"
                    variant='light'
                    border={'1px solid #075da8'}
                    content="By turning this on you can set up the email you will receive when the form is submitted"
                    place='right'
                />
            </div>
            <EmailTab />

            <div className='tab-content2'>
                {activeTab == 'setting' ? (
                    <form className='settings-form' id='adminForm' onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label>Recipient Email <span className="required">*</span></label>
                            <input placeholder="Enter recipient email" type="text" {...rules.RecipientEmail} id="recipient" className="form-control" />
                            {errors.RecipientEmail && <span className='error'>{errors.RecipientEmail.message}</span>}
                        </div>

                        <div className="form-group">
                            <label>Sender Email <span className="required">*</span></label>
                            <select id="senderEmail" {...rules.SenderEmail} className="form-control">
                            <option disabled value={''}>Select a sender email</option>
                                {verifiedEmails.map((ve: any, index: number) => <option key={index} value={ve._id}>{ve.SenderName} {'<' + ve.SenderEmail + '>'}</option>)}
                            </select>
                            {errors.SenderEmail && <span className='error'>{errors.SenderEmail.message}</span>}
                        </div>

                        <div className="form-group">
                            <label>Bcc</label>
                            <input placeholder="Enter bcc email" type="text" {...rules.Bcc} id="bcc" className="form-control" />
                            {errors.Bcc && <span className='error'>{errors.Bcc.message}</span>}
                        </div>

                        <div className="form-group">
                            <label>Sender Name <span className="required">*</span></label>
                            <input {...rules.SenderName} placeholder="Enter sender name" type="text" id="senderName" className="form-control" />
                        </div>

                        {<div className="form-cb-group2 mb-2">
                            <label>Include blank fields </label>
                            <label className="switch">
                                <input {...rules.ShowBlankFields} type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                            <a style={{color: '#075da8', marginLeft:'8px'}} id="adminShowBlankFields"><i className="fa fa-info-circle"></i></a>
                            <Tooltip
                                anchorSelect="#adminShowBlankFields"
                                variant='light'
                                border={'1px solid #075da8'}
                                content="Using this toggle you can include (toggle on) or exclude (toggle off) the blank fields left by the respondents in the admin email."
                                place='right'
                            />
                        </div>}
                    </form>) : (
                    <form id='adminForm' className='settings-form lg w-100' onSubmit={handleSubmit(onSubmit)}>
                        <SubjectInput errors={errors} defaultValue={adminEmail.Subject} items={items} onChange={(value: string) => onSubjectChange(value)} onBlur={() => onFieldBlur('Subject')} />

                        <div className="form-group d-flex justify-content-between">
                            <label>Email Body</label>
                            <div className="actn-btns">
                                <button type='button' className='btn outline' onClick={() => setShowPreview(true)}>Preview</button>
                                <button type='button' className='btn' onClick={() => setShowEditor(true)}>Edit</button>
                            </div>
                        </div>

                        <iframe id='myFrame' ref={framRef} onLoad={onPreviewLoad} srcDoc={adminEmail.Content}
                            style={{ width: '100%', height: '100%', border: 'none', userSelect: 'none', pointerEvents: 'none' }}></iframe>
                    </form>
                )}
            </div>
            {showEditor ? <TemplateEditor setShowEditor={setShowEditor} items2={pages} content={adminEmail.Content} TemplateType='Admin'></TemplateEditor> : ''}
            {showPreview ?
                <PreviewEditor setShowPreview={setShowPreview} items2={pages} content={adminEmail.Content}></PreviewEditor>
                : ''}

            <div style={{ display: 'none' }}>
                <button form='adminForm' ref={submitBtn} type="submit" className="btn">Save</button>
            </div>
        </div>
    )
}

export default AdminEmail;